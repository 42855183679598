<template>
  <b-card class="blog-edit-wrapper">
    <!-- form -->
    <validation-observer ref="addFormvalidate">
    <b-form class="mt-2" style="width: 60%" @submit.prevent="save">
        <b-row>
          <b-col md="12">
            <b-col md="12">
              <b-form-group
                label="الصفحة"
                label-for="blog-edit-course"
                class="mb-2"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Courses"
                  rules=""
                >
                  
{{addForm.page_name}}

                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          

            <b-col cols="12">
              <b-form-group label="العنوان" label-for="blog-content" class="mb-2">
                <validation-provider
                  #default="{ errors }"
                  name="title"
                  rules="required"
                >
                  <b-form-input v-model="addForm.title" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col cols="12">
              <b-form-group
                label="الوصف"
                label-for="blog-content"
                class="mb-2"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Description"
                  rules="required"
                >
                  <b-form-input v-model="addForm.description" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col cols="12">
              <b-form-group
                label="الكلمات المفتاحية"
                label-for="blog-content"
                class="mb-2"
              >
                <validation-provider
                  #default="{ errors }"
                  name="kew"
                  rules="required"
                >
                  <b-form-tags v-model="addForm.keywords" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-col>

          <b-col cols="12" class="mt-50">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-1"
              @click="save"
            >
              حفظ
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              variant="outline-secondary"
               @click="$router.go(-1)"
            >
              Back
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
    <!--/ form -->
  </b-card>
</template>

<script>
import {
  BCard,
  BMedia,
  BFormRadio,
  BFormCheckbox,
  BFormRadioGroup,
  BCardText,
  BMediaAside,
  BMediaBody,
  BForm,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BImg,
  BFormFile,
  BLink,
  BFormTags,
  BButton,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import vSelect from "vue-select";
import { quillEditor } from "vue-quill-editor";
import { required, email } from '@validations'
import Ripple from "vue-ripple-directive";
import { useInputImageRenderer } from "@core/comp-functions/forms/form-utils";
import { ref, reactive,computed } from "@vue/composition-api";
import store from '@/store'
import { useRouter } from '@core/utils/utils'
import Vue from "vue";
export default {
  setup() {
    // const store = useStore();
        const refInputEl = ref(null);
      const refInputE2 = ref(null);
    const refPreviewEl = ref(null);
     const refPreviewE2 = ref(null);

      const Tabimage = ref("media/svg/files/blank-image.svg");
           const { route } = useRouter()
      const id = route.value.params.id
    //   const productId = productSlug.substring(id.lastIndexOf('-') + 1)
    console.log(id)
      store.dispatch('seo/GetSeoPage',{id})
        .then(response => {
         
           store.commit('seo/GET_SEO_PAGES', response?.data.data)
          })
 
    const Form = computed(() => store.state.seo.seoPage);
    const addForm=reactive(Form)
 
   
   const { inputImageRenderer } = useInputImageRenderer(
      refInputEl,
      (base64) => {
        refPreviewEl.value.src = base64;
      }
    );
    const { inputImageRendererTab } = useInputImageRenderer(
      refInputE2,
      (base64) => {
        console.log("dd")
        refPreviewE2.value.src = base64;
      }
    );
    
     const addFormvalidate=ref()

    const save = () => {
      
      console.log('addForm')
      console.log(addForm)
      const formData = new FormData();

    
        
         addFormvalidate.value.validate().then(success => {
        if (success) {
        

formData.append("description", addForm.value.description);
formData.append("title", addForm.value.title);
formData.append("keywords", addForm.value.keywords);
formData.append("page_name", addForm.value.page_name);
formData.append('_method', 'put')
      console.log("formData",formData)
           store.dispatch('seo/UpdateSeoPage',{id,formData})
        .then(response => {
           Vue.swal({
            title: 'تم التحديث  ',
                  icon: "success",
                  customClass: {
                    confirmButton: "btn btn-primary",
                  },
                  buttonsStyling: false,
                });
          
        }).catch((error) => {
      
            Vue.swal({
              title: "",
              text: `${error.response.data.message}`,
              icon: "error",
              confirmButtonText: "حسنا",
              customClass: {
                confirmButton: "btn btn-primary",
              },
              buttonsStyling: false,
            });
          });
         
      console.log("alt_header_image", addForm);
        }})
    };
    return {
    refInputEl,
      refPreviewEl,
      refInputE2,
       refPreviewE2,
      inputImageRendererTab,
     
      Tabimage,
      addForm,
      inputImageRenderer,
      addFormvalidate,
      id,
      Form,
      addForm,
   
     

      save,
       required, email
    };
  },

  components: {
    BCard,
    BMedia,
  ValidationProvider, ValidationObserver,
    BCardText,
    BMediaAside,
    BMediaBody,
    BFormRadio,
    BFormCheckbox,
    BFormRadioGroup,
    BForm,
    BLink,
    BImg,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BFormFile,
    vSelect,
    quillEditor,
    BFormTags
  },

  directives: {
    Ripple,
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/quill.scss";
@import "@core/scss/vue/pages/page-blog.scss";
</style>
